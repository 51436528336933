<template>
  <transition name="menu">
    <form-sifatsurat :is-edit="true"/>
  </transition>
</template>

<script>
import formSifatsurat from './components/formSifatsurat'

export default {
  name: 'EditSifatSurat',
  components: { formSifatsurat }
}
</script>

